/*
 * File: c:\Users\twalton\Documents\Projects\goMDOT\goMDOT\ClientApp\src\components\HTMLContent\HTMLContent.css
 * Project: c:\Users\twalton\Documents\Projects\goMDOT\goMDOT\ClientApp
 * Created Date: Wednesday July 10th 2019
 * Author: Walton, Timothy
 * -----
 * Last Modified: Thursday December 12th 2019 2:02:35 pm
 * Modified By: the developer known as Walton, Timothy
 * -----
 */
/* p.slate-body1{
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  min-height: 1.5em;
  margin: 0;
}
p.slate-body2{
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  min-height: 1.5em;
  letter-spacing: 0.01071em;
  margin: 0;
}
h6.slate-subtitle2{
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.57;
  min-height: 1.57em;
  letter-spacing: 0.00714em;
  margin: 0;
}
h6.slate-subtitle1{
  color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.75;
    min-height: 1.75em;
    letter-spacing: 0.00938em;
    margin: 0;
}
h6.slate-heading6{
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  min-height: 1.6em;
  letter-spacing: 0.0075em;
  margin: 0;
}
h5.slate-heading5{
  color: rgba(0, 0, 0, 0.87);
    font-size: 1.5rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.33;
    min-height: 1.33em;
    letter-spacing: 0em;
    margin: 0;
}
h4.slate-heading4{
  color: rgba(0, 0, 0, 0.87);
    font-size: 2.125rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.17;
    min-height: 1.17em;
    letter-spacing: 0.00735em;
    margin: 0;
}
h3.slate-heading3{
  color: rgba(0, 0, 0, 0.87);
    font-size: 3rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.04;
    min-height: 1.04em;
    letter-spacing: 0em;
    margin: 0;
}
h2.slate-heading2{
  color: rgba(0, 0, 0, 0.87);
    font-size: 3.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1;
    min-height: 1em;
    letter-spacing: -0.00833em;
    margin: 0;
}
h1.slate-heading1{
  color: rgba(0, 0, 0, 0.87);
  font-size: 6rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1;
  min-height: 1em;
  letter-spacing: -0.01562em;
  margin: 0;
}
.text-editor .ql-container {
  min-height: 10em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.ql-editor{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding: 0px !important;
}
.ql-snow .ql-editor {
    padding: 12px 15px !important;
}
.text-editor .ql-container p,
.ql-editor p{
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
.text-editor .ql-container h1,
.ql-editor h1{
  font-size: 6rem;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.01562em;
}
.text-editor .ql-container h2,
.ql-editor h2{
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.00833em;
}
.text-editor .ql-container h3,
.ql-editor h3{
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.04;
  letter-spacing: 0em;
}
.text-editor .ql-container h4,
.ql-editor h4{
  font-size: 2.125rem;
  font-weight: 400;
  line-height: 1.17;
  letter-spacing: 0.00735em;
}
.text-editor .ql-container h5,
.ql-editor h5{
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0em;
}
.text-editor .ql-container h6,
.ql-editor h6{
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}
.text-editor .ql-toolbar {
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom: none;
  border-radius: 0;
}
.text-editor .ql-editor {
  min-height: 18em;
  background-color: #FFF !important;
  border-radius: 0;
}
#toolbar-container .ql-font span[data-label="Roboto"]::before {
  font-family: "Roboto";
}
.ql-font-roboto {
  font-family: "Roboto";
}
.ql-container{
  border-radius: 0 !important;
}
.ql-active{
  border: 1px solid #555d66 !important;
  border-radius: 4px;
  background-color: #dfdfdf !important;
  box-shadow: inset 0 2px 5px -3px rgba(0,0,0,.3);
}
.ql-editor{
  height: auto !important;
  min-height: 40px;
} */
.dx-htmleditor-content{
  overflow-y: auto;
}
.dx-htmleditor-content img {
  vertical-align: middle;
  padding-right: 10px;
}
.dx-theme-material-typography{
  background: transparent !important;
}
.dx-htmleditor-toolbar-format .dx-button-content:before,
.dx-toolbar-menu-custom .dx-button-content:before {
  content: "";
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}
.dx-theme-material-typography a{
  color: #2b7bb9;
  text-decoration: none;
  transition: color 300ms linear;
}
.dx-theme-material-typography a:hover{
  color: #3b94d9;
  text-decoration: underline;
}
.html-editor-widget.dx-htmleditor-content{
  padding: 0;
}
.dx-htmleditor-content ol, .dx-htmleditor-content ul{
  padding-left: 0px;
}