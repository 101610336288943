.header-btn-hover{
    color: inherit;
    border: 0;
    margin: 0;
    cursor: pointer;
    display: inline-flex;
    outline: none;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    justify-content: center;
    padding: 0 10px;
}
.header-btn-hover:hover{
  background: rgba(51,51,51,0.8);
}
.header-btn-hover:hover span{
  color: #FFF !important;
}
.dropdown-container{
  white-space: nowrap;
}
.dropdown-container-split{
  float: left;
  width: 50%;
}
.dropdown {
  display: none;
  position: absolute;
  top: 65px;
  left: 0px;
  border-radius: 0px;
  z-index: 2;
  background: rgb(91,91,91);
  table-layout: fixed;
}
.dropdown-row{
  display: table-row;  
  white-space: nowrap;
}
.dropdown-cell.single{
  margin: 0;
  padding: 5px;
  display: block;
  width: 100%;
  white-space: nowrap;
}
.dropdown-cell.dual{
  margin: 0;
  padding: 5px;
  display: table-cell;
  width: 50%;
}
.header-btn-hover:hover .dropdown{
  display: table;
  
}
.dropdown-sub-item{
  margin: 5px 10px !important;
}
.slideup-enter {
  transform: translateY(100%);
  opacity: 0;
}

.slideup-enter-active {
  transform: translateY(0%);
  opacity: 100;
  -webkit-transition: transform .6s ease, opacity .6s ease;
  transition: transform .6s ease, opacity .6s ease;
}
.slideup-exit {
  transform: translateY(0%);
  opacity: 100;
}
.slideup-exit-active {
  transform: translateY(100%);
  opacity: 0;
  -webkit-transition: transform .6s ease, opacity .6s ease;
  transition: transform .6s ease, opacity .6s ease;
}