/* Used to show large text columns in grid */
.prewrap {
    white-space: pre-wrap;
}
.vertTop {
    vertical-align: top !important;
}
/*TODO: Organize these into regions and document what they would be used for*/
.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}
/*media screen,projection*/
.material-icons {
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga";
    font-feature-settings: "liga" 1;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    min-height: 100vh;
    background-color: #fafafa;
}

.absolute-center {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    text-align: center;
}

.absolute-bottom-right {
    position: absolute !important;
    bottom: 0px !important;
    right: 0px !important;
}

.absolute-top-right {
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
}

.absolute-bottom-left {
    position: absolute !important;
    bottom: 0px !important;
    left: 0px !important;
}

.header-icon {
    float: left;
    margin: -20px 15px 0;
    padding: 15px;
    border-radius: 3px;
}

.relative {
    position: relative;
}
/*Blur for every single browser except IE(Thanks IE)*/
.blur {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    transition: all 0.5s ease;
    pointer-events: none;
}

    .blur.show {
        visibility: visible;
        opacity: 1;
        -webkit-filter: blur(0);
        -moz-filter: blur(0);
        -o-filter: blur(0);
        -ms-filter: blur(0);
        filter: blur(0);
        pointer-events: all;
    }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .blur {
        visibility: hidden;
        opacity: 0;
    }
}
.default-shadow{
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.drawer-header {
    min-height: 64px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.drawer-border {
    border-right: none;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
/* dxLIST Template */
.list-img-container,
.list-icon-container {
    position: relative;
    width: 50px;
    height: 50px;
    float: left;
    margin-right: 10px;
}

.list-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    padding: 5px;
    color: white;
    border-radius: 50%;
}

.list-icon-img {
    width: 100%;
    height: 100%;
    background-size: 35px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.list-img-container::before,
.list-letter-container::before,
.list-icon-container::before {
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    content: "\f005";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    color: #0d47a1;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    background: #90caf9;
    padding: 10px;
    border-radius: 50%;
    z-index: 2;
}

.list-img-container::before,
.list-letter-container::before {
    font: 45px/1 DXicons;
}

.list-icon-container::before {
    font: 30px/1 DXicons;
}

.dx-list-item-selected .list-img-container::before,
.dx-list-item-selected .list-letter-container::before,
.dx-list-item-selected .list-icon-container::before {
    visibility: visible;
    opacity: 100;
    transform: translate(-50%, -50%) scale(1.05, 1.05);
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected {
    background-color: transparent !important;
}

.list-img-container .org-chart-img {
    border-radius: 50%;
}

.list-item-container {
    flex: 1 1 auto;
    padding: 0 16px;
    min-width: 0;
}

.list-item-header {
    max-width: calc(100% - 95px);
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.5em;
}

.list-item-detail {
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.875rem;
    font-weight: 400;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    margin: 0;
    display: block;
}

.white-text .dx-icon {
    color: #fff !important;
}

.dx-loadpanel-content {
    border-radius: 5px;
}
