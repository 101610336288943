/*Layout Component*/
body {
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.route-content{
  min-height: calc(100vh - 261px);
  width: 100%;
  overflow: hidden;
  position: relative;
}
.top-relative{
    max-width: 1920px;
    margin: auto;
    background: #FFF;
}
.header-sticky{
  position:sticky;
  top: 0px;
  z-index: 1500;
}
.layoutWrapper {
    min-height: 100vh;
    height: auto;
    display: -ms-grid;
    display: grid;
    grid-template-columns: 300px 1fr;
    -ms-grid-columns: 300px 1fr;
}

.layoutNavButton {
    display: none !important;
}

.layoutNavTitle {
    padding-left: 10px;
    flex: 1 1 0%;
}

.layoutHeader {
    height: 50px;
    display: flex;
    overflow: hidden;
    align-items: center;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    z-index: 1300;
    position: sticky;
    top: 0px;
    left: 0px;
    margin-bottom: 10px;
}

.layoutNav {
    display: flex;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-column-align: center;
    grid-row: 1;
    grid-column: 1;
    z-index: 2;
    width: 100%;
    border-right: 1px solid silver;
}

.layoutMain {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-row: 1;
    grid-column: 2;
    overflow: auto;
    background: #efefef;
    max-height: 100vh;
    min-height: 100vh;
    position: relative;
}

/*NavMenu Component*/
.navWrapper {
    min-height: 100vh;
    height: auto;
    width: 100%;
    min-width: 300px;
}

.navHeader {
    height: 50px;
    display: flex;
    justify-content:space-between;
    padding-left: 10px;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row-align: center;
    grid-row: 1;
    grid-column: 1;
    align-items: center;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    position: relative;
    color: inherit;
}

    .navHeader::after {
        content: "";
        box-shadow: 0px -5px 0px 5px;
        position: absolute;
        right: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
    }

.navMain {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-row: 2;
    grid-column: 1;
    overflow: auto;
}

.navMainLoad {
    width: 240px;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-row: 2;
    grid-column: 1;
    align-items: center;
    justify-content: center;
}

/*Media Queries*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .layoutWrapper {
        height: 100vh;
    }

    .layoutHeader {
        overflow: auto;
    }

    .navWrapper {
        height: 100vh;
    }
}

@media screen and (max-width: 1280px) {
    .layoutWrapper {
        height: auto;
        grid-template-columns: 1fr;
        -ms-grid-columns: 1fr;
    }

    .layoutNavPopout {
        display: inline !important;
    }

    .layoutNavButton {
        display: inline !important;
    }

    .layoutHeader {
        -ms-grid-column: 1;
        grid-column: 1;
    }

    .layoutNav {
        visibility: hidden;
    }

    .layoutMain {
        -ms-grid-column: 1;
        grid-column: 1;
        border-left: none;
    }
}
.dx-dropdowneditor-active .dx-invalid-message.dx-overlay {
  display: none;
}
/* Any DX component in Toolbar should have white text and border */
.layoutHeader .dx-numberbox-spin-up-icon,
.layoutHeader .dx-numberbox-spin-down-icon,
.layoutHeader .dx-texteditor-input,
.layoutHeader .dx-field-item-label-text,
.flex-toolbar .dx-numberbox-spin-up-icon,
.flex-toolbar .dx-numberbox-spin-down-icon,
.flex-toolbar .dx-texteditor-input,
.flex-toolbar .dx-field-item-label-text,
.white-text .dx-numberbox-spin-up-icon,
.white-text .dx-numberbox-spin-down-icon,
.white-text .dx-texteditor-input,
.white-text .dx-field-item-label-text{
    color: #FFFFFF
}
.black-text .dx-numberbox-spin-up-icon,
.black-text .dx-numberbox-spin-down-icon,
.black-text .dx-texteditor-input,
.black-text .dx-field-item-label-text{
    color: #000
}
.layoutHeader .dx-texteditor:not(.dx-textarea) .dx-texteditor-container:after,
.flex-toolbar .dx-texteditor:not(.dx-textarea) .dx-texteditor-container:after,
.white-text .dx-texteditor:not(.dx-textarea) .dx-texteditor-container:after {
    border-bottom: 1px solid rgba(255, 255, 255, 0.42);
}
.layoutHeader .dx-field-item,
.flex-toolbar .dx-field-item {
    padding-bottom: 5px;
}
.layoutHeader .dx-texteditor:not(.dx-textarea).dx-state-hover .dx-texteditor-container:after,
.flex-toolbar .dx-texteditor:not(.dx-textarea).dx-state-hover .dx-texteditor-container:after,
.white-text .dx-texteditor:not(.dx-textarea).dx-state-hover .dx-texteditor-container:after {
    border-bottom: 1px solid rgba(255, 255, 255, 0.87);
    border-top: 1px solid rgba(255, 255, 255, 0.87);
}
.dx-popup-content{
  padding: 0px !important;
}
.dx-popup-content.dx-dialog-content {
    padding: 24px !important;
}
.user-img-bubble{
  width: 50px;
  height: 50px;
  float: left;
  border-radius: 50%;
  margin-right: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.white-text .dx-placeholder{
  color: rgba(255,255,255,0.87)
}
.dx-textarea{
  box-shadow: none !important;
  border-bottom: 1px solid #757575;
  border-radius: 0;
}
.dx-textarea.dx-textbox.dx-texteditor.dx-widget.dx-state-hover{
  border-bottom: 2px solid black;
}
.dx-textarea.dx-textbox.dx-texteditor.dx-widget.dx-state-focused{
  border-bottom: 2px solid #03A9F4;
}
.dx-form .dx-item {
  align-items: flex-end;
}
.dx-state-disabled.dx-widget, .dx-state-disabled .dx-widget {
  opacity: 0.60 !important;
}
.white-text .dx-dropdowneditor-icon:before {
  color: #fff !important;
}
.dx-button-flat {
    background-color: transparent !important;
    box-shadow: none !important;
    color: #fff !important;
}
.dx-button-flat .dx-icon{
    color: #fff !important;
}
.dx-button-flat.dx-state-hover {
  background-color: rgba(0, 0, 0, 0.08) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.dx-button-flat.dx-state-focused {
  background-color: rgba(0, 0, 0, 0.15) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.dx-button-flat.dx-state-active {
  background-color: rgba(0, 0, 0, 0.2) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.dx-button-flat.dx-state-disabled {
  background: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.dx-button-flat.dx-state-disabled .dx-icon {
  opacity: 0.6;
}
.dx-button-flat.dx-state-disabled .dx-button-text {
  color: rgba(0, 0, 0, 0.35);
}
.list-icon-container::before {
  font: 30px/1 DXicons;
}
.dx-list-item-selected .list-img-container::before, .dx-list-item-selected .list-letter-container::before, .dx-list-item-selected .list-icon-container::before {
  visibility: visible;
  opacity: 100;
  transform: translate(-50%, -50%) scale(1.05, 1.05);
}
.list-img-container::before, .list-letter-container::before, .list-icon-container::before {
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  content: "\f005";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  color: #0d47a1;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  background: #90caf9;
  padding: 10px;
  border-radius: 50%;
  z-index: 2;
}
