.component-edit-hover:hover{
  border: 2px solid #2196F3;
  margin: -2px;
}
.component-edit-button,
.component-remove-button{
  display: none;
}
.component-edit-hover:hover > div > div > .component-edit-button,
.component-edit-hover:hover > div > div > .component-remove-button{
  display: inline-block;
}